/* about section */

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: 5rem;
  height: 100vh; /* Assuming the Navbar height is 100px */
  box-sizing: border-box;
  position: relative;
  background: var(--s-bg-c);
}

#about-profile-pic {
  /* border-radius: 50%; */
  width: 420px;
  height: 440px;
}

.about-container .text-animate {
  position: relative;
  width: 54.1rem;
}

.text-animate {
  font-size: 2.5rem;
  font-weight: 700;
  color: transparent;
}

.about-container .text-animate h3 {
  font-size: 2.7rem;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 0.7px var(--m-c);
  background-image: linear-gradient(var(--m-c), var(--m-c));
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  background-position: 0 0;
  animation: animatedItText 6s linear infinite;
  animation-delay: 2s;
  text-align: start;
}

.about-container .text-animate h3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 2px solid var(--m-c);
  z-index: -1;
  animation: animatedCursor 6s linear infinite;
  animation-delay: 2s;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 80rem;
  gap: 2rem;
}

.about-container h1 {
  font-size: 6.2rem;
  font-weight: 700;
  line-height: 1.3;
}

.about-container h2 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.3;
}

.social-media p {
  font-size: 15px;
  text-align: justify;
}

.about-btn-cv {
  display: inline-block;
  padding: 1rem 3.7rem;
  background: var(--m-c);
  border-radius: 4rem;
  box-shadow: 0 0 1rem var(--m-c);
  font-size: 2rem;
  color: var(--s-bg-c);
  letter-spacing: 0.1rem;
  font-weight: 600;
  transition: 0.5s ease;
}

.btn-cv:hover {
  box-shadow: none;
}

@keyframes animatedItText {
  0%,
  10%,
  100% {
    background-position: -54rem 0;
  }

  65%,
  85% {
    background-position: 0 0;
  }
}

@keyframes animatedCursor {
  0%,
  10%,
  100% {
    width: 0;
  }

  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }
  75%,
  81% {
    opacity: 0;
  }
}

@media (max-width: 1234px) {
  .text-animate {
    font-size: 1.5rem;
    font-weight: 700;
    color: transparent;
  }

  .about-container {
    display: flex;
    flex-direction: column;
    max-width: 95rem;
    padding: 0 6rem 0 2rem;
  }

  .about-container .text-animate h3 {
    font-size: 1.9rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -38rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .about-container h1 {
    font-size: 4.1rem;
  }

  .about-container h2 {
    font-size: 2.8rem;
  }

  #about-profile-pic {
    display: none;
  }

  .social-media p {
    font-size: 12px;
    text-align: justify;
  }
  .about-container .text-animate {
    position: relative;
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 925px) {
  #about-profile-pic {
    display: none;
  }

  /* about section */

  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 85px); /* Assuming the Navbar height is 100px */
    /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
    padding-bottom: 14rem;
  }

  .about-container {
    display: flex;
    flex-direction: column;
    max-width: 85rem;
    padding: 0 6rem 0 4rem;
  }

  .about-container .text-animate h3 {
    font-size: 3.2rem !important;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
    width: 66rem;
  }

  .text-animate {
    font-size: 1.5rem;
    font-weight: 700;
    color: transparent;
  }

  .about-container .text-animate h3 {
    font-size: 1.9rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -65rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .about-container .text-animate h3::before {
    display: none;
  }

  .about-container h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .about-container h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
  }

  .about .about-container .text-animate h3 {
    font-size: 2rem;
  }

  .about-container .btn-cv {
    display: inline-block;
    background: var(--m-c);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--m-c);
    font-size: 1.7rem;
    color: var(--s-bg-c);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.5s ease;
  }

  .text-animate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: 0.2rem solid var(--m-c);
    border-radius: 50%;
    font-size: 2rem;
    color: var(--m-c);
    margin: 3rem 1.5rem 3rem 0;
    transition: 0.5s ease;
  }
}

@media (max-width: 768px) {
  /* about section */

  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh; /* Assuming the Navbar height is 100px */
    /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
    gap: 2rem;
  }

  .about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 5.5rem 0 5.5rem;
  }

  #about-profile-pic {
    display: none;
  }
  .about-container .text-animate h3 {
    font-size: 1.5rem !important;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
    width: 37rem;
    text-align: center;
  }

  .about-container .text-animate h3::before {
    display: none;
  }

  .about-container h1 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .about-container h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
    margin-top: -3rem;
  }

  .text-animate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .social-media {
    width: 100% !important;
  }

  .social-media p {
    font-size: 10px;
    text-align: justify;
    margin-top: 2rem;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -47rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .about-btn-cv {
    display: inline-block;
    background: var(--m-c);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--m-c);
    font-size: 1rem;
    color: var(--s-bg-c);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.5s ease;
  }
}
