/* Home section */

.home {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5rem;
  height: 100vh; /* Assuming the Navbar height is 100px */
  /* Offset to start below the Navbar */
  box-sizing: border-box;
  position: relative;
}

#profile-pic {
  border-radius: 50%;
  width: 420px;
  height: 540px;
}

.home-container .text-animate,
.about-container .text-animate,
.projects-container .text-animate,
.contact .text-animate {
  position: relative;
  width: 54.1rem;
}

.text-animate {
  font-size: 2.5rem;
  font-weight: 700;
  color: transparent;
}

.home-container .text-animate h3,
.about-container .text-animate h3,
.projects-container .text-animate h3,
.skills-container .text-animate h3,
.contact .text-animate h3 {
  font-size: 2.7rem;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 0.7px var(--m-c);
  background-image: linear-gradient(var(--m-c), var(--m-c));
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  background-position: 0 0;
  animation: animatedItText 6s linear infinite;
  animation-delay: 2s;
}

.home-container .text-animate h3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 2px solid var(--m-c);
  z-index: -1;
  animation: animatedCursor 6s linear infinite;
  animation-delay: 2s;
}

.home-container {
  max-width: 60rem;
}

.home-container h1 {
  font-size: 6.2rem;
  font-weight: 700;
  line-height: 1.3;
}

.home-container h2 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.3;
}

.social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: 0.2rem solid var(--m-c);
  border-radius: 50%;
  font-size: 2rem;
  color: var(--m-c);
  margin: 3rem 1.5rem 3rem 0;
  transition: 0.5s ease;
}

.social-media a:hover {
  background: var(--m-c);
  color: var(--s-bg-c);
  box-shadow: 0 0 1rem var(--m-c);
}

.btn-cv {
  display: inline-block;
  padding: 1rem 3.7rem;
  background: var(--m-c);
  border-radius: 4rem;
  box-shadow: 0 0 1rem var(--m-c);
  font-size: 2.2rem;
  color: var(--s-bg-c);
  letter-spacing: 0.1rem;
  font-weight: 600;
  transition: 0.5s ease;
}

.btn-cv:hover {
  box-shadow: none;
}

@keyframes animatedItText {
  0%,
  10%,
  100% {
    background-position: -54rem 0;
  }

  65%,
  85% {
    background-position: 0 0;
  }
}

@keyframes animatedCursor {
  0%,
  10%,
  100% {
    width: 0;
  }

  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }
  75%,
  81% {
    opacity: 0;
  }
}

@media (max-width: 1234px) {
  html {
    font-size: 55%;
  }

  .navbar a {
    margin-left: 2rem;
  }

  .text-animate {
    font-size: 1.5rem;
    font-weight: 700;
    color: transparent;
  }

  .home-container .text-animate h3 {
    font-size: 1.9rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -38rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .home-container h1 {
    font-size: 4.1rem;
  }

  .home-container h2 {
    font-size: 2.8rem;
  }

  #profile-pic {
    border-radius: 50%;
    width: 350px;
    height: 420px;
  }

  .home-container .text-animate {
    position: relative;
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 925px) {
  .section {
    padding: 10rem 4% 2rem;
  }

  #profile-pic {
    width: 200px;
    height: 250px;
    margin-top: 0rem;
  }

  /* Home section */

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh; /* Assuming the Navbar height is 100px */
    margin-top: 30px; /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    max-width: 100rem;
    margin: 0;
  }

  .home-container .text-animate h3 {
    font-size: 3.2rem !important;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
    width: 66rem;
  }

  .text-animate {
    font-size: 1.5rem;
    font-weight: 700;
    color: transparent;
  }

  .home-container .text-animate h3 {
    font-size: 1.9rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -65rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .home-container .text-animate h3::before {
    display: none;
  }

  .home-container h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .home-container h2 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.3;
  }

  .home .home-container .text-animate h3 {
    font-size: 2rem;
  }

  .home-container .btn-cv {
    display: inline-block;
    background: var(--m-c);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--m-c);
    font-size: 1.7rem;
    color: var(--s-bg-c);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.5s ease;
  }

  .text-animate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: 0.2rem solid var(--m-c);
    border-radius: 50%;
    font-size: 2rem;
    color: var(--m-c);
    margin: 3rem 1.5rem 3rem 0;
    transition: 0.5s ease;
  }
}

@media (max-width: 768px) {
  /* Home section */

  .home {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 100vh; /* Assuming the Navbar height is 100px */
    /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
    gap: 0rem;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  #profile-pic {
    border-radius: 50%;
    width: 160px;
    height: 180px;
    margin-top: -10rem;
  }
  .home-container .text-animate h3 {
    font-size: 1.8rem !important;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
    width: 37rem;
  }

  .home-container .text-animate h3::before {
    display: none;
  }

  .home-container h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .home-container h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
  }

  .text-animate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -47rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: 0.2rem solid var(--m-c);
    border-radius: 50%;
    font-size: 2rem;
    color: var(--m-c);
    margin: 3rem 1.5rem 3rem 0;
    transition: 0.5s ease;
  }
  .home-container .btn-cv {
    display: inline-block;
    background: var(--m-c);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--m-c);
    font-size: 1.5rem;
    color: var(--s-bg-c);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.5s ease;
  }
}
