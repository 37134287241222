.video-container {
  position: relative;
  width: 100%;
  height: 83vh; /* Full viewport height */
  overflow: hidden;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make sure the video covers the entire area */
}
