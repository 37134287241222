/* Contact section */

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: 3rem;
  height: 100vh; /* Assuming the Navbar height is 100px */
  box-sizing: border-box;
  position: relative;
  background: var(--s-bg-c);
}

.contact-container-title {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#contact .text-animate h3 {
  text-align: center;
  font-size: 2.4rem;
}

.contact-container-title h2 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
}

.contac-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4rem;
}

.contact-form-container {
  width: 47%;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding-bottom: 1rem;
}

.row span i {
  padding-top: 0.7rem;
  font-size: 2.7rem;
  text-decoration: none;
}

.contact-info-name {
  font-size: 1.8rem;
  font-weight: bold;
}

.row .sub-title {
  font-size: 1.7rem;
}

.row .contact form {
  margin: 0 auto;
  text-align: center;
}

.contact {
  background: var(--s-bg-c);
}

.contact form .input-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box .input-field {
  position: relative;
  width: 49%;
  margin: 0.8rem 0;
}

.contact form .input-box .input-field input,
.contact form .textarea-field textarea {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  color: var(--t-c);
  background: transparent;
  border-radius: 0.6rem;
  border: 0.2rem solid var(--m-c);
}

.contact form .input-box .input-field input::placeholder,
.contact form .textarea-field textarea::placeholder {
  color: var(--t-c);
}

.contact form .input-box .input-field input:focus ~ .focus,
.contact form .input-box .input-field input:valid ~ .focus,
.contact form .textarea-field textarea:focus ~ .focus,
.contact form .textarea-field textarea:valid ~ .focus {
  width: 100%;
}

.contact form .focus {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--s-bg-c);
  border-radius: 0.6rem;
  z-index: -1;
  transition: 0.5s;
}

.contact form .textarea-field {
  position: relative;
  margin: 0.8rem 0 2.7rem;
  display: flex;
  height: 16rem;
}

.contact form .textarea-field textarea {
  resize: none;
}

.contact form .btn-box .btns .btn-cv {
  cursor: pointer;
}

.btns .btn-cv {
  font-size: 1.8rem;
  padding: 0.7rem 3rem 0.7rem 3rem;
}

.text-base {
  font-size: 1rem;
  letter-spacing: 0.6rem;
  color: var(--m-c);
}

/* For small device */

@media (max-width: 1234px) {
  .contact-container-title h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 925px) {
  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 85px); /* Assuming the Navbar height is 100px */
    /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
    padding-bottom: 14rem;
  }

  .contact-container-title {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  #contact .text-animate h3 {
    text-align: center;
    font-size: 2.4rem;
  }

  .contact-container-title h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .contac-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4rem;
  }

  .contact-form-container {
    width: 47%;
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    padding-bottom: 1rem;
  }

  .row span i {
    padding-top: 0.7rem;
    font-size: 2rem;
    text-decoration: none;
  }

  .contact-info-name {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .row .sub-title {
    font-size: 1.3rem;
  }

  .row .contact form {
    margin: 0 auto;
    text-align: center;
  }

  .contact {
    background: var(--s-bg-c);
  }

  .contact form .input-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .contact form .input-box .input-field {
    position: relative;
    width: 49%;
    margin: 0.8rem 0;
  }

  .contact form .input-box .input-field input,
  .contact form .textarea-field textarea {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    font-size: 1.2rem;
    color: var(--t-c);
    background: transparent;
    border-radius: 0.6rem;
    border: 0.2rem solid var(--m-c);
  }

  .contact form .input-box .input-field input::placeholder,
  .contact form .textarea-field textarea::placeholder {
    color: var(--t-c);
  }

  .contact form .input-box .input-field input:focus ~ .focus,
  .contact form .input-box .input-field input:valid ~ .focus,
  .contact form .textarea-field textarea:focus ~ .focus,
  .contact form .textarea-field textarea:valid ~ .focus {
    width: 100%;
  }

  .contact form .focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--s-bg-c);
    border-radius: 0.6rem;
    z-index: -1;
    transition: 0.5s;
  }

  .contact form .textarea-field {
    position: relative;
    margin: 0.8rem 0 2.7rem;
    display: flex;
    height: 20rem;
  }

  .contact form .textarea-field textarea {
    resize: none;
  }

  .contact form .btn-box .btns .btn-cv {
    cursor: pointer;
  }

  .btns .btn-cv {
    font-size: 1.4rem;
    padding: 0.7rem 3rem 0.7rem 3rem;
  }

  .text-base {
    font-size: 1rem;
    letter-spacing: 0.6rem;
    color: var(--m-c);
  }
}

@media (max-width: 768px) {
  .header {
    background: var(--bg-c);
  }

  /* Contact section */

  .contact {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    min-height: auto;
    padding-bottom: 0rem;
    gap: 3rem;
  }

  .contac-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
  }

  .contact-container-title h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  #contact .text-animate h3 {
    text-align: center;
    font-size: 1.5rem;
  }

  .contact-form-container {
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    padding-bottom: 1rem;
  }

  .row span i {
    padding-top: 0.7rem;
    font-size: 1.3rem;
  }

  .contact-info-name {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .row .sub-title {
    font-size: 1.2rem;
  }

  .contact form .textarea-field {
    position: relative;
    height: 7rem;
    margin: 0.8rem 0 2.7rem;
    display: flex;
  }

  .contact form .input-box .input-field {
    position: relative;
    width: 49%;
    margin: 0.1rem 0;
  }

  .contact form .input-box .input-field input,
  .contact form .textarea-field textarea {
    width: 100%;
    height: 70%;
    padding: 1.2rem;
    font-size: 1rem;
    color: var(--t-c);
    background: transparent;
    border-radius: 0.6rem;
    border: 0.2rem solid var(--m-c);
  }

  .btn-box {
    margin-top: -4rem;
  }

  .btns .btn-cv {
    font-size: 1.1rem;
    padding: 0.7rem 2rem 0.7rem 2rem;
    margin-top: 1rem;
  }
}
