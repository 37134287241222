/* Container for the projects section */
.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: 5rem;
  width: 100%;
  height: 83vh; /* Full viewport height */
  box-sizing: border-box;
  position: relative;
  background: var(--s-bg-c);
}

.projects-head {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
}

.projects-head span {
  color: var(--m-c); /* Highlight color */
}

.projects-container h2 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.3;
  /* padding-top: -10rem; */
}

.text-animate h3 {
  font-size: 1.8rem;

  text-align: center;
}

/* .carousel-item {
  text-align: center;
  padding: 20px;
  box-sizing: border-box; 
} */

/* Strong reflection with more space between dots and the image */
/* .carousel-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s;§
  
   -webkit-box-reflect: below 5px
    linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3));
} */

/* Adjust the space between the image and dots */
/* .slick-dots {
  bottom: -80px; 
} */

/* .carousel-item img:hover {
  transform: scale(1.05); 
} */

/* Custom styles for Slick dots */
/* .slick-dots li button:before {
  font-size: 12px; 
  color: rgb(188, 184, 184); 
  opacity: 1; 
} */

.slick-dots li.slick-active button:before {
  color: var(--m-c); /* Color of active dot (blue) */
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Reseteo básico */

/* Bento Grid */
.grid-item {
  background-color: transparent;

  /* 8px */
  border-radius: 0.5rem;
  padding-block-end: 2.5rem;
  text-align: center;
  display: grid;
  justify-content: center;
  padding-top: 2rem;
  position: relative;
}

/* Estilo del contenedor del carrusel */
.carousel-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenido del carrusel */
.carousel-content {
  display: flex;
  animation: scroll 100s linear infinite;
}

/* Cada elemento del carrusel */
.carousel-item {
  min-width: 450px;
  /* height: 350px; */
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

/* Animación para el scroll automático */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

#section {
  display: flex;
  width: 80%;
  height: 100%;
  max-width: 80%;
  max-height: 100%;
  place-items: center;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  opacity: 1;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 16.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
  overflow: hidden;
}
@media (max-width: 1024px) {
  .projects-head {
    font-size: 2.5rem;
  }

  .text-animate h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    gap: 5rem;
    height: 100vh; /* Assuming the Navbar height is 100px */
    box-sizing: border-box;
    position: relative;
    background: var(--s-bg-c);
    padding-bottom: 0rem;
  }
  #section {
    display: flex;
    width: 80%;
    height: 100%;
    max-width: 80%;
    max-height: 100%;
    place-items: center;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    opacity: 1;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 16.5%,
      rgb(0, 0, 0) 87.5%,
      rgba(0, 0, 0, 0) 100%
    );
    overflow: hidden;
  }
  .projects-head {
    font-size: 2rem;
  }

  .carousel-item {
    min-width: 250px;
    /* height: 350px; */
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .projects-container h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
    /* padding-top: -10rem; */
  }
  .projects-container .text-animate h3 {
    font-size: 1.8rem;
    margin-bottom: 40px;
    text-align: center;
  }

  .projects-container .text-animate,
  .contact .text-animate {
    position: relative;
    width: 34.1rem;
  }
  .carousel-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s;
    /* Full reflection with more fade */
    /* -webkit-box-reflect: below 5px
    linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)); */
  }

  .slick-dots li button:before {
    font-size: 7px; /* Set the size of the dots */
    color: rgb(188, 184, 184); /* Color of inactive dots (white) */
    opacity: 1; /* Ensures the dot color is fully opaque */
  }
}
