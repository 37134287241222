/* Footer section */

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem 13%;
  background: var(--bg-c);
}

/* Footer text styling */
.footer-text {
  font-size: 1.6rem;
}

/* Footer icon styles */
.footer-icon a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background: var(--m-c);
  border: 0.2rem solid var(--m-c);
  border-radius: 0.6rem;
  z-index: 1;
  overflow: hidden;
}

.footer-icon a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--s-bg-c);
  z-index: -1;
  transition: 0.5s;
}

.footer-icon a:hover::before {
  width: 100%;
}

.footer-icon a i {
  font-size: 2.4rem;
  color: var(--bg-c);
  transition: 0.5s;
}

.footer-icon a:hover i {
  color: var(--m-c);
}

/* Media query for screen sizes between 769px and 925px */
@media (min-width: 769px) and (max-width: 925px) {
  .footer-text {
    font-size: 1.4rem;
  }

  .footer {
    padding: 2rem 4%;
  }
}

/* Media query for smaller screens (mobile) */
@media (max-width: 768px) {
  .footer {
    padding: 1.5rem 4.5%;
  }

  .footer-text {
    font-size: 1rem;
  }

  .footer-icon a i {
    font-size: 1.8rem;
    color: var(--bg-c);
    transition: 0.5s;
    padding: 0rem;
  }
}
