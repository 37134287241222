/* Navbar section */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 12.5%;
  background: var(--bg-c);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  transition: 0.3s;
}

.header.sticky {
  background: var(--s-bg-c);
}

.logo {
  font-size: 3.5rem;
  color: var(--t-c);
  font-weight: 600;
  cursor: default;
}

span {
  color: var(--m-c);
}

.navbar a {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--t-c);
  margin-left: 5rem;
  transition: 0.3s;
}

.navbar a:hover,
.navbar a.active {
  color: var(--m-c);
}

#menu-icon {
  font-size: 3.7rem;
  color: var(--t-c);
  cursor: pointer;
  display: none;
}

@media (max-width: 1234px) {
  html {
    font-size: 55%;
  }

  .navbar a {
    margin-left: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 925px) {
  .header {
    padding: 2rem 10%;
  }

  .section {
    padding: 10rem 4% 2rem;
  }

  /* Navbar section */

  #menu-icon {
    display: block;
    font-size: 4rem;
  }

  .logo {
    font-size: 3.8rem;
    letter-spacing: 2px;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    text-align: center;
    padding: 1rem 4%;
    background: var(--m-c);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transition: 0.25s ease;
    transition-delay: 0.25s;
    z-index: 1;
  }

  .navbar.active {
    left: 0;
    transition-delay: 0s;
  }

  .navbar .active-nav {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: var(--bg-c);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    z-index: -1;
    transition: 0.25s ease;
    transition-delay: 0s;
  }

  .navbar.active .active-nav {
    left: 0;
    transition-delay: 0.25s;
  }

  .navbar a {
    display: block;
    font-size: 2.2rem;
    margin: 3rem 0;
    transform: translateX(-20rem);
    transition: 0.25s ease;
    transition-delay: 0s;
  }

  .navbar.active a {
    transform: translateX(0);

    transition-delay: 0.25s;
  }
}

@media (max-width: 768px) {
  .header {
    background: var(--bg-c);
  }

  /* Navbar section */

  #menu-icon {
    display: block;
    font-size: 4rem;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    text-align: center;
    padding: 1rem 4%;
    background: var(--m-c);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    transition: 0.25s ease;
    transition-delay: 0.25s;
    z-index: 1;
  }

  .navbar.active {
    left: 0;
    transition-delay: 0s;
  }

  .navbar .active-nav {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: var(--bg-c);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    z-index: -1;
    transition: 0.25s ease;
    transition-delay: 0s;
  }

  .navbar.active .active-nav {
    left: 0;
    transition-delay: 0.25s;
  }

  .navbar a {
    display: block;
    font-size: 1.6rem;
    margin: 3rem 0;
    transform: translateX(-20rem);
    transition: 0.25s ease;
    transition-delay: 0s;
  }

  .navbar.active a {
    transform: translateX(0);

    transition-delay: 0.25s;
  }

  .logo {
    font-size: 2.5rem;
  }
}
