#carousel-wrapper-new {
  padding: 1rem;
  width: clamp(360px, 90vw, 820px);
  display: flex;
  flex-flow: column;
  gap: 2rem;
  position: relative;
  margin: auto;
  margin-bottom: 13rem;
  margin-top: 3rem;
}

#carousel-image-display {
  border-radius: 20px;
  overflow: hidden;
  min-height: 30vmin;
  aspect-ratio: 16 / 9;
  box-shadow: 0 0.375em 0.67em #0003, 0 0.5em 1.3em #0002;
}

#carousel-screen {
  display: flex;
  background-image: linear-gradient(12deg, #aaa, #eee);
  position: relative;
  user-select: none;
}

#carousel-image {
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

#carousel-thumbnails {
  display: flex;
  gap: 0.75rem;
  height: 100%;
}

#carousel-btn-prev,
#carousel-btn-next {
  flex: 1 0 max(2.75rem, 5.834%);
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#carousel-icon-prev,
#carousel-icon-next {
  margin-inline: auto;
  width: max(80%, 1.5rem);
  height: max(80%, 1.5rem);
  fill: hsl(204, 3%, 47%);
}

#carousel-slider {
  list-style: none;
  display: flex;
  gap: 1.2rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

#carousel-slider::-webkit-scrollbar {
  display: none;
}

.carousel-slide-new {
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  scroll-snap-align: center;
  flex: 1 0 calc((100% / 5) - 10px);
}

.carousel-slide-new.active-new {
  outline: 0.125em solid hsl(198, 86%, 53%);
  outline-offset: -0.37em;
}

#carousel-thumbnail {
  height: 100%;
  width: 100%;
}

#carousel-thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
