/* skills section */

.skills {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto 0;
  height: 100vh; /* Assuming the Navbar height is 100px */
  box-sizing: border-box;
  position: relative;
  background: var(--bg-c);
}

#skills-profile-pic {
  /* border-radius: 50%; */
  width: 420px;
  height: 440px;
}

.skills-container .text-animate {
  position: relative;
  width: 54.1rem;
}

.text-animate {
  font-size: 2.5rem;
  font-weight: 700;
  color: transparent;
}

.skills-container .text-animate h3 {
  font-size: 2.7rem;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 0.7px var(--m-c);
  background-image: linear-gradient(var(--m-c), var(--m-c));
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  background-position: 0 0;
  animation: animatedItText 6s linear infinite;
  animation-delay: 2s;
  text-align: start;
}

.skills-container .text-animate h3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-right: 2px solid var(--m-c);
  z-index: -1;
  animation: animatedCursor 6s linear infinite;
  animation-delay: 2s;
}

.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 60rem;
  gap: 2rem;
}

.skills-container h1 {
  text-align: center;
  font-size: 6.2rem;
  font-weight: 700;
  line-height: 1.3;
}

.skills-container h2 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.3;
}

h4 {
  font-size: 22px;
}

.social-media p {
  font-size: 15px;
  text-align: justify;
}

.skills-btn-cv {
  display: inline-block;
  padding: 1rem 3.7rem;
  background: var(--m-c);
  border-radius: 4rem;
  box-shadow: 0 0 1rem var(--m-c);
  font-size: 1.7rem;
  color: var(--s-bg-c);
  letter-spacing: 0.1rem;
  font-weight: 600;
  transition: 0.5s ease;
}

.btn-cv:hover {
  box-shadow: none;
}

.skills-experiance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 7rem;
}

.skills-experiance ul {
  position: relative;
  display: flex;
  transform-style: preserve-3d;
  transform: rotate(-25deg) skew(25deg);
}

.skills-experiance ul li {
  position: relative;
  list-style: none;
  width: 55px;
  height: 55px;
  margin: 0 3rem;
}

.skills-experiance ul li:before {
  content: '';
  position: absolute;
  bottom: -10px;
  top: 0;
  left: -9px;
  width: 9px;
  height: 100%;
  background: #2a2a2a;
  transform-origin: right;
  transform: skewY(-49deg);
}

.skills-experiance ul li:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background: #2a2a2a;
  transform-origin: top;
  transform: skewX(-41deg);
}

.skills-experiance ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: var(--s-bg-c);
  font-size: 4rem !important;
  transition: 0.2s;
}

.skills-experiance ul li:hover span {
  z-index: 1000;
  transition: 0.5s;
  color: #fff;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.05);
}

.skills-experiance ul li:hover span:nth-child(5) {
  transform: translate(40px, -40px);
  opacity: 1;
}
.skills-experiance ul li:hover span:nth-child(4) {
  transform: translate(30px, -30px);
  opacity: 0.8;
}
.skills-experiance ul li:hover span:nth-child(3) {
  transform: translate(20px, -20px);
  opacity: 0.6;
}
.skills-experiance ul li:hover span:nth-child(2) {
  transform: translate(10px, -10px);
  opacity: 0.4;
}
.skills-experiance ul li:hover span:nth-child(1) {
  transform: translate(0px, 0px);
  opacity: 0.2;
}

.skills-experiance .skills-experience-one ul li:nth-child(1):hover span {
  background: #ff4000;
}
.skills-experiance .skills-experience-one ul li:nth-child(2):hover span {
  background: #0040ff;
}
.skills-experiance .skills-experience-one ul li:nth-child(3):hover span {
  background: #ffff00;
}
.skills-experiance .skills-experience-one ul li:nth-child(4):hover span {
  background: #00ffff;
}
.skills-experiance .skills-experience-two ul li:nth-child(1):hover span {
  background: #8000ff;
}
.skills-experiance .skills-experience-two ul li:nth-child(2):hover span {
  background: #40ff00;
}
.skills-experiance .skills-experience-two ul li:nth-child(3):hover span {
  background: #05cee9;
}
.skills-experiance .skills-experience-two ul li:nth-child(4):hover span {
  background: #0903b0;
}
.skills-experiance .skills-experience-three ul li:nth-child(1):hover span {
  background: #41d9f4;
}
.skills-experiance .skills-experience-three ul li:nth-child(2):hover span {
  background: #f33a02;
}
.skills-experiance .skills-experience-three ul li:nth-child(3):hover span {
  background: #0e53f4;
}
.skills-experiance .skills-experience-three ul li:nth-child(4):hover span {
  background: #ff4000;
}

@keyframes animatedItText {
  0%,
  10%,
  100% {
    background-position: -54rem 0;
  }

  65%,
  85% {
    background-position: 0 0;
  }
}

@keyframes animatedCursor {
  0%,
  10%,
  100% {
    width: 0;
  }

  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }
  75%,
  81% {
    opacity: 0;
  }
}

@media (max-width: 1280px) {
  .text-animate {
    font-size: 1.5rem;
    font-weight: 700;
    color: transparent;
  }

  .skills-container {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    padding: 0 2rem 0 2rem;
  }

  .skills-container .text-animate h3 {
    font-size: 1.9rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -38rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .skills-container h1 {
    font-size: 4.1rem;
  }

  .skills-container h2 {
    font-size: 2.8rem;
  }

  #skills-profile-pic {
    display: none;
  }

  .social-media p {
    font-size: 12px;
    text-align: justify;
  }
  .skills-container .text-animate {
    position: relative;
    width: 100%;
  }

  .skills-experiance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6rem;
  }

  .skills-experiance ul {
    position: relative;
    display: flex;
    transform-style: preserve-3d;
    transform: rotate(-25deg) skew(25deg);
  }

  .skills-experiance ul li {
    position: relative;
    list-style: none;
    width: 35px;
    height: 35px;
    margin: 0 3rem;
  }

  .skills-experiance ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background: #2a2a2a;
    transform-origin: right;
    transform: skewY(-49deg);
  }

  .skills-experiance ul li:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skewX(-41deg);
  }

  .skills-experiance ul li span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: var(--s-bg-c);
    font-size: 3rem !important;
    transition: 0.2s;
  }
}

@media (min-width: 769px) and (max-width: 925px) {
  #skills-profile-pic {
    display: none;
  }

  /* skills section */

  .skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 85px); /* Assuming the Navbar height is 100px */
    /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
    padding-bottom: 10rem;
  }

  .skills-container {
    display: flex;
    flex-direction: column;
    max-width: 85rem;
    padding: 0 8rem 0 8rem;
  }

  .skills-container .text-animate h3 {
    font-size: 2.6rem !important;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
    width: 66rem;
    text-align: center;
  }

  .text-animate {
    font-size: 1.5rem;
    font-weight: 700;
    color: transparent;
  }

  /* .skills-container .text-animate h3 {
    font-size: 1.9rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
  } */

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -65rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .skills-container .text-animate h3::before {
    display: none;
  }

  .skills-container h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .skills-container h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.3;
  }

  .skills .skills-container .text-animate h3 {
    font-size: 2rem;
  }

  .skills-container .skills-btn-cv {
    display: inline-block;
    background: var(--m-c);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--m-c);
    font-size: 1.3rem;
    color: var(--s-bg-c);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.5s ease;
  }

  .text-animate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .skills-experiance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 4rem;
    padding-top: 6rem;
  }

  .skills-experiance ul {
    position: relative;
    display: flex;
    transform-style: preserve-3d;
    transform: rotate(0deg) skew(0deg);
  }

  .skills-experiance ul li {
    position: relative;
    list-style: none;
    width: 35px;
    height: 35px;
    margin: 0 6rem;
  }

  .skills-experiance ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background: #2a2a2a;
    transform-origin: right;
    transform: skewY(-49deg);
  }

  .skills-experiance ul li:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skewX(-41deg);
  }

  .skills-experiance ul li span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: var(--s-bg-c);
    font-size: 3rem !important;
    transition: 0.2s;
  }
}

@media (max-width: 768px) {
  /* skills section */

  .skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh; /* Assuming the Navbar height is 100px */
    /* Offset to start below the Navbar */
    box-sizing: border-box;
    position: relative;
  }

  .skills-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 3rem 0 3rem;
    gap: 2rem;
  }

  #skills-profile-pic {
    display: none;
  }
  .skills-container .text-animate h3 {
    font-size: 1.8rem !important;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--m-c);
    background-image: linear-gradient(var(--m-c), var(--m-c));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: animatedItText 6s linear infinite;
    animation-delay: 2s;
    width: 37rem;
    text-align: center;
  }

  .skills-container .text-animate h3::before {
    display: none;
  }

  .skills-container h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
  }

  .skills-container h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
  }

  h4 {
    font-size: 14px;
  }

  .text-animate {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: -2rem;
  }

  .social-media {
    margin-top: 1rem;
  }

  .social-media p {
    font-size: 10px;
    text-align: justify;
  }

  @keyframes animatedItText {
    0%,
    10%,
    100% {
      background-position: -47rem 0;
    }

    65%,
    85% {
      background-position: 0 0;
    }
  }

  @keyframes animatedCursor {
    0%,
    10%,
    100% {
      width: 0;
    }

    65%,
    78%,
    85% {
      width: 100%;
      opacity: 1;
    }
    75%,
    81% {
      opacity: 0;
    }
  }

  .skills-btn-cv {
    display: inline-block;
    background: var(--m-c);
    border-radius: 4rem;
    box-shadow: 0 0 1rem var(--m-c);
    font-size: 1rem;
    color: var(--s-bg-c);
    letter-spacing: 0.1rem;
    font-weight: 600;
    transition: 0.5s ease;
  }

  .skills-experiance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3rem;
    padding-top: 5rem;
  }

  .skills-experiance ul {
    position: relative;
    display: flex;
    transform-style: preserve-3d;
    transform: rotate(0deg) skew(0deg);
  }

  .skills-experiance ul li {
    position: relative;
    list-style: none;
    width: 30px;
    height: 30px;
    margin: 0 3rem;
  }

  .skills-experiance ul li:before {
    content: '';
    position: absolute;
    bottom: -10px;
    top: 0;
    left: -9px;
    width: 9px;
    height: 100%;
    background: #2a2a2a;
    transform-origin: right;
    transform: skewY(-49deg);
  }

  .skills-experiance ul li:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #2a2a2a;
    transform-origin: top;
    transform: skewX(-41deg);
  }

  .skills-experiance ul li span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: var(--s-bg-c);
    font-size: 2rem !important;
    transition: 0.2s;
  }
}
