@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,400;1,700&family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,400;0,900;1,400;1,700&family=Rubik:wght@400;600;700;800&family=Titillium+Web:wght@300;400;600;700&family=Ubuntu:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg-c: #1f242d;
  --s-bg-c: #323946;
  --t-c: #fff;
  --m-c: #0ef;
}

html {
  font-size: 60%;
  overflow-x: hidden;
}

body {
  background: var(--bg-c);
  color: var(--t-c);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#main-wrapper {
  min-height: 100vh; /* Full height of the viewport */
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

/* Main content should take all the available space */
#main-content {
  flex: 1; /* Makes the content grow to push footer to the bottom */
}

section {
}
